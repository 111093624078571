<template>
	<div class="offline">
		You seem to be offline...
	</div>
</template>

<script>
	export default {
		name: 'Oflline'
	}
</script>